import { memo } from 'react';

import { Box } from '@/shared/ui/box';
import { Checkbox } from '@/shared/ui/checkbox';
import { Text } from '@/shared/ui/text';

import { Link } from './checkbox-list.styles';
import { CheckboxListComponentProps } from './checkbox-list.types';

const CheckboxListComponent = memo(({ checkboxes, checkedState, onChange }: CheckboxListComponentProps) => {
	return checkboxes.map((checkbox, index) => (
		<Checkbox
			key={index}
			alignItems="start"
			content={
				<Box gap={4}>
					{typeof checkbox.text === 'string' && <Text label={checkbox.text} />}
					{typeof checkbox.text !== 'string' && checkbox.text}
					{checkbox.linkText && checkbox.link && <Link label={checkbox.linkText} href={checkbox.link} />}
				</Box>
			}
			checked={checkedState[index]}
			onChange={() => onChange(index)}
		/>
	));
});

export default CheckboxListComponent;
