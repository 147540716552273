import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Router } from '@/app/providers/router';
import * as Styled from '@/app/styles/app-layouts';
import { useUser } from '@/entities/user';
import { useAgreementsModal } from '@/features/agreements';
import { AppRoutes } from '@/shared/constants/app-routes.constant';
import { useMatchMedia } from '@/shared/lib/hooks/use-match-media.hook';
import { Header } from '@/widgets/header';
import { Sidebar } from '@/widgets/sidebar';

const HtmlContentComponent = () => {
	const isTablet = useMatchMedia('tabletAndMobile');
	const { acceptedLatestAgreements } = useUser();
	const [openConditionsAndPrivacyPolicyModal] = useAgreementsModal();
	const { pathname } = useLocation();
	const [isModalOpened, setIsModalOpened] = useState(false);

	useLayoutEffect(() => {
		if (pathname === AppRoutes.TermsOfService || pathname === AppRoutes.Privacy) {
			return;
		} else if (!acceptedLatestAgreements && !isModalOpened) {
			openConditionsAndPrivacyPolicyModal();

			setIsModalOpened(true);
		}
	}, [pathname]);

	return (
		<>
			{!isTablet && <Sidebar />}
			<Styled.ContentWrapper>
				<Header />
				<Router />
			</Styled.ContentWrapper>
		</>
	);
};

export default HtmlContentComponent;
